import React, {useEffect} from "react"
import { AmplifySignOut } from '@aws-amplify/ui-react';
import api from "../apiAgent"

const Home = () => {
	useEffect(() => {
		try {
			api.getDownloadLink("some-key")
				.then(r => {
					console.log(r.data)
				})
				.catch(r => {
					//handle error
				})
		} catch (error) {
			console.error(error);
		}
	},[])
	return (
		<>
		    <AmplifySignOut />
		    <h1>Home page</h1>
		</>
	)
}

export default Home
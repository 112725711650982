import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'

import {
    Home,
    NotFound
} from './pages'

const routes = [
    {
        path: ['/'],
        component: withAuthenticator(Home),
        exact: true,
    }
]

export const Routes = () => {

    return (
        <>
            <Switch>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                ))}
                <Route component={NotFound} />
            </Switch>
        </>
    )
}

export default Routes

import './App.css';
import Routes from './routes'

function App() {
  return (
  <div>
    <Routes />
  </div>
  );
}

export default App;
import axios from "axios";
import { Auth } from 'aws-amplify';
class ApiAgent {
	constructor(){
		this.token = undefined
		this.restClient = undefined
		this.initRestClient()
	}
	initRestClient () {
		const defaultPath = process.env.REACT_APP_ENV?.toLowerCase() || 'dev'
		const authorize = async (axiosConfig) => {
			if(!Boolean(this.token)){
				const session = await Auth.currentSession()
				this.token = session.idToken.jwtToken
			}
			axiosConfig.headers = {...axiosConfig.headers, 'Authorization': `Bearer ${this.token}`}
			return axiosConfig
		}
		this.restClient = axios.create({
		  baseURL: `https://api-passport.juliacastrofx.com/${defaultPath}`,
		  timeout: 15000,
		  headers: {}
		})
		this.restClient.interceptors.request.use(authorize)
	}

	async getUserSession(){
		const session = await Auth.currentSession()
		return session
	}

	async getDownloadLink(objectKey) {
		try {
			const config = {url: `assets?key=${objectKey}`, method: `GET`}
			const res = await this.restClient(config)
		return res;
		} catch (error) {
			console.error(error)
			throw error
		}
	}
}

export default new ApiAgent()